.sc-slideshow-plus {

    @include media-breakpoint-up(xl) {
        &.with-megamenu {
            position: relative;
            z-index: 4;
            margin-left: 272px;

            @include media-breakpoint-up(xxl) {
                margin-left: 318px;
            }

            @media (min-width: $break-xxl) {
                margin-left: 388px;
            }
        }
    }

    &-block {
        display: flex !important;
        flex-direction: column;

        > div {
            display: none;
            
            &:nth-child(1) {
                display: block;
            }
        }

        .slick-dots {
            position: absolute;
            bottom: 0.125rem;

            @include media-breakpoint-up(md) {
                bottom: 0.25rem;
            }
            
            @include media-breakpoint-up(lg) {
                bottom: -0.75rem;
            }

            button {
                background: #E4E4EB;
            }

            .slick-active button {
                background: #FFFFFF;
            }
        }
    }

    &-item {
        padding: 1rem 1rem 2.5rem;
        height: inherit !important;
        background: #582BD8;

        .h1,
        p {
            color: #FFFFFF;
        }

        @include media-breakpoint-up(lg) {
            padding: 2rem 2rem 2.5rem;
        }

        @media (min-width: $break-xxl) {
            padding: 2.75rem 2rem;
        }

        &:not(:first-child) {
            display: none;
        }

        &-fullimg {
            img {
                width: 100%;

                @include media-breakpoint-up(xl) {
                    height: 360px;
                }

                @include media-breakpoint-up(xxl) {
                    height: 392px;
                }

                @media (min-width: $break-xxl) {
                    height: 410px;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .h1 {
                font-size: 1.5rem;
            }

            &-img {
                width: 100%;
                block-size: auto;
            }
        }
    }

    &-info {
        @include media-breakpoint-up(xxl) {
            max-width: 50%;

            button {
                margin-top: 1rem;
            }
        }
    }

    &-text {
        padding: 1rem 0;

        @include media-breakpoint-up(lg) {
            padding: 2rem 0;
        }

        p + p {
            margin-top: 1rem;
        }
    }

    &-specials {
        &:not(.slick-initialized) {
            display: flex;
            max-width: 100%;
            overflow-x: hidden;
            @include media-breakpoint-up(sm) {
                gap: 1rem;
            }

            .sc-slideshow-plus-specials-item {
                flex: 0 0 100%;

                @include media-breakpoint-up(sm) {
                    flex: 0 0 calc(50% - 0.5rem);
                }

                @media (min-width: 1440px) {
                    flex: 0 0 calc(33.333333% - 0.675rem);
                }
            }
        }
        
        .slick-list {
            margin: 0 0 0 -1rem;

            @include media-breakpoint-up(xl) {
                margin: 0 0 0 -1.5rem;
            }
        }

        .slick-track {
            overflow: visible;
            overflow-x: hidden;
            align-items: stretch;
        }

        .slick-slide {
            margin: 0 0 0 1rem;
            display: flex !important;
            flex-direction: column;
            justify-content: flex-start;
            height: auto !important;
            min-height: 100% !important;

            @include media-breakpoint-up(xl) {
                margin: 0 0 0 1.5rem;
            }

            transition: border-color $mainTransition;

            &:hover {
                box-shadow: none;
                border-color: #eaeaea;
            }
        }

        .slick-arrow {
            position: absolute;
            top: -34px;
            right: 0;
            width: 28px;
            height: 28px;
            box-shadow: none;
            filter: none;
            border: $mainBorder;

            .sc-btn-icon {
                width: 16px;
                height: 16px;
            }
        }

        .slick-slider-btn-prev {
            left: auto;
            right: 38px;
        }

        &-block {
            &-title {
                &:hover {
                    text-decoration: underline;
                    color: $linksColor;
                }
            }
        }
    }
}

.default-slideshow {
    .slick-dots {
        padding: 1rem;
        margin: 0 auto;
        display: flex;
        align-content: center;
        justify-content: center;

        @include media-breakpoint-up(lg) {
            padding: 1.5rem;
        }
    }

    img {
        width: 100%;
        block-size: auto;
    }
}